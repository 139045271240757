import { Divider, Form, Input } from "antd";
import { BuiltInProviderType } from "next-auth/providers";
import classNames from "classnames";
import { LiteralUnion } from "next-auth/react/types";
import { Button } from "@components/atoms/Button";
import { NextAuthButton } from "@components/atoms/NextAuthButton";
import { NextAuthProvider } from "@domain/auth/types";
import styles from "./index.module.scss";

type Props = {
  providers: { key: LiteralUnion<BuiltInProviderType, string> }[];
  loading: boolean;
  onEmailSignIn: (values: CredentialFormValues) => void;
  onProviderSignIn: (
    provider: LiteralUnion<BuiltInProviderType, string>,
  ) => void;
  previousAuthMethod: NextAuthProvider | null;
};

type CredentialFormValues = {
  email: string;
};

const dividerConfig = {
  color: "#fff",
  borderTop: "#fff",
  fontSize: "17px",
  marginTop: "30px",
  marginBottom: "30px",
};

const inputConfig = {
  backgroundColor: "#292F37",
  height: "52px",
  border: "none",
  borderRadius: "10px",
  fontSize: "14px",
  paddingLeft: "20px",
  color: "#fff",
};

const inputWrapperConfig = {
  marginTop: "17px",
};

export const SignInForm = ({
  providers,
  onEmailSignIn,
  onProviderSignIn,
  previousAuthMethod,
}: Props) => {
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Pierakstīties</h3>
      <div className={styles.authWrapper}>
        {providers.map(({ key }) => (
          <div key={key}>
            <NextAuthButton
              previousAuthMethod={previousAuthMethod}
              provider={key}
              onClick={() => onProviderSignIn(key)}
            />
          </div>
        ))}
      </div>

      <div className={styles.divider}>
        <Divider style={dividerConfig}>vai</Divider>
      </div>

      <h4
        className={classNames(styles.formTitle, {
          [styles.highLight]: previousAuthMethod === null,
        })}
      >
        Pierakstīties ar e-pastu
      </h4>
      <div className={styles.formWrapper}>
        <Form<CredentialFormValues> onFinish={onEmailSignIn}>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Ievadi korektu e-pastu!",
                type: "email",
              },
            ]}
            style={inputWrapperConfig}
            name={"email"}
          >
            <Input
              spellCheck="false"
              placeholder="E-pasta adrese"
              style={inputConfig}
              data-testid="email-input"
            />
          </Form.Item>
          <div
            className={classNames(styles.buttonWrapper, {
              [styles.highLight]: previousAuthMethod === null,
            })}
          >
            <Button data-testid="sign-in-btn" type="primary" htmlType="submit">
              Pierakstīties
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
