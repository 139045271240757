import { GetServerSideProps } from "next";
import React from "react";
import { ClientSafeProvider, signIn } from "next-auth/react";
import { useRouter } from "next/router";
import { getToken } from "next-auth/jwt";
import { wrap } from "@integration/next/ssr";
import { fetchAuthProviders } from "@domain/auth/ssr";
import { SignInForm } from "@components/organisms/SignInForm";
import { findQueryParam } from "@utils/uri/findQueryParam";
import { NextPageWithLayout } from "@components/layouts/types";
import { SignInLayout } from "@components/layouts/SignInLayout";
import { SignInComponent } from "@components/atoms/SignInComponent";
import {
  NextAuthProvider,
  parseAuthConnectionType,
  toNextAuthProviderType,
} from "@domain/auth";
import { previousAuthMethod } from "@domain/auth/ssr/auth-method-cookies";
import { trpc } from "@domain/account/trpc";
import styles from "./index.module.scss";

const PROVIDER_ORDER = ["google", "facebook", "github"];

type Props = {
  returnTo: string | null;
  providers: ClientSafeProvider[];
  previousAuthMethod: NextAuthProvider | null;
};

const Page: NextPageWithLayout<Props> = ({
  returnTo,
  providers,
  previousAuthMethod,
}) => {
  const router = useRouter();
  const { mutateAsync: emailCredentialSignIn, isLoading: loading } =
    trpc.auth.emailCredentialSignIn.useMutation();
  const options = {
    callbackUrl: `/sign-in/callback${returnTo ? "?return-to=" + returnTo : ""}`,
  };
  return (
    <div className={styles.wrapper}>
      <SignInComponent />
      <SignInForm
        previousAuthMethod={previousAuthMethod}
        providers={providers
          .filter((it) => it.type === "oauth")
          .map((it) => ({ key: it.id }))}
        loading={loading}
        onEmailSignIn={async (values) => {
          const res = await emailCredentialSignIn({
            email: values.email,
            returnTo: returnTo || undefined,
          });
          const query = new URLSearchParams({
            email: res.email,
            "account-id": res.accountId,
            "account-exists": String(res.accountExists),
          });
          const authConnections = (res.authConnections || [])
            .map(parseAuthConnectionType)
            .map((it) =>
              it ? toNextAuthProviderType({ connectionType: it }) : undefined,
            )
            .filter((it) => it !== undefined);
          if (authConnections.length > 0) {
            query.append("auth-connections", authConnections.join("|"));
          }
          if (returnTo) {
            query.append("return-to", returnTo);
          }
          router.push(`/sign-in/email-credentials?${query}`);
        }}
        onProviderSignIn={(it) => signIn(it, { ...options })}
      />
    </div>
  );
};

Page.getLayout = ({ page, props }) => {
  return (
    <SignInLayout
      showHeader
      showLogoAndLogoShadow
      showLogoShadow={false}
      {...props}
    >
      {page}
    </SignInLayout>
  );
};
Page.skipProviders = true;

export const getServerSideProps: GetServerSideProps<Props> = wrap<Props>(
  async (ctx) => {
    const token = await getToken({ req: ctx.req });
    if (token) {
      return {
        redirect: {
          permanent: false,
          destination: "/",
        },
      };
    }
    const returnTo = findQueryParam({ ctx, key: "return-to" });
    const providers = await fetchAuthProviders();
    return {
      props: {
        returnTo: returnTo || null,
        providers: providers.sort(
          (a, b) => PROVIDER_ORDER.indexOf(a.id) - PROVIDER_ORDER.indexOf(b.id),
        ),
        previousAuthMethod:
          previousAuthMethod({ req: ctx.req, res: ctx.res }) || null,
      },
    };
  },
);

export default Page;
