import React, { FC } from "react";
import { Row, Col } from "antd";
import { HeaderLogo } from "@components/atoms/HeaderLogo";
import { SignInFooter } from "@components/atoms/SignInFooter";
import CodelexIcon from "@public/svg/codelex-logo.svg";
import styles from "./index.module.scss";

type Props = {
  showHeader: boolean;
  showLogoAndLogoShadow: boolean;
  showLogoShadow: boolean;
  children?: React.ReactNode;
};

export const SignInLayout: FC<Props> = ({
  children,
  showHeader,
  showLogoAndLogoShadow,
  showLogoShadow,
}) => {
  return (
    <div className={styles.wrapper}>
      <Row className={styles.wrapperHeader}>
        <Col
          xs={{ offset: 1, span: 22 }}
          sm={{ offset: 1, span: 22 }}
          md={{ offset: 2, span: 20 }}
          lg={{ offset: 2, span: 20 }}
          xl={{ offset: 3, span: 18 }}
          xxl={{ offset: 0, span: 24 }}
        >
          {showHeader && (
            <div className={styles.capWrapper}>
              <div className={styles.largeScreenCap}>
                <HeaderLogo link={"https://www.codelex.io/"} />
              </div>
            </div>
          )}
        </Col>
      </Row>

      <div className={styles.spaceBetween}>
        <Row className={styles.pageContainer}>
          <Col
            xs={{ offset: 1, span: 22 }}
            sm={{ offset: 1, span: 22 }}
            md={{ offset: 2, span: 20 }}
            lg={{ offset: 2, span: 20 }}
            xl={{ offset: 3, span: 18 }}
            xxl={{ offset: 0, span: 24 }}
          >
            <div className={styles.capWrapper}>
              <div className={styles.largeScreenCap}>
                {showLogoAndLogoShadow && (
                  <>
                    <CodelexIcon className={styles.codelexIconShadow} />
                    <CodelexIcon className={styles.codelexIconFront} />
                  </>
                )}
                {showLogoShadow && (
                  <CodelexIcon className={styles.codelexIconShadowOnly} />
                )}

                {children}
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            xs={{ offset: 1, span: 22 }}
            sm={{ offset: 1, span: 22 }}
            md={{ offset: 2, span: 20 }}
            lg={{ offset: 2, span: 20 }}
            xl={{ offset: 3, span: 18 }}
            xxl={{ offset: 0, span: 24 }}
          >
            <div className={styles.capWrapper}>
              <div className={styles.largeScreenCapFooter}>
                <div className={styles.wrapperFooter}>
                  <SignInFooter />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
