import { Button as AntDButton, ButtonProps as AntDButtonProps } from "antd";
import classNames from "classnames";
import styles from "./index.module.scss";

export const Button = (props: AntDButtonProps) => {
  return (
    <AntDButton className={classNames(styles.customButton)} {...props}>
      {props.children}
    </AntDButton>
  );
};
