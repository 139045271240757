import Link from "next/link";
import Image from "next/legacy/image";
import styles from "./index.module.scss";

type Props = {
  link: string;
};

export const HeaderLogo = ({ link }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <Link href={link}>
          <Image
            src={"/logo/header.svg"}
            alt="codelex"
            height={20}
            width={127}
          />
        </Link>
      </div>
    </div>
  );
};
